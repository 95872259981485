import { getLayoutTypePath, isLayoutPGOneColumn, isLayoutPGSideBySide, LAYOUT_PG_GRID, SECTION_POST_LIST } from '@wix/communities-blog-client-common';
import { get } from 'lodash';
import { getAppSettings } from '../../common/selectors/app-settings-base-selectors';
import { getDefaultLayout } from '../../common/selectors/layout-selectors';

export const getPostListLayoutType = ({ state, isMobile }) => {
  let layoutType;

  if (isMobile) {
    const mobileLayoutType = getLayoutTypeParam({ state, isMobile: true });
    const desktopLayoutType = getLayoutTypeParam({ state });
    layoutType = mobileLayoutType || desktopLayoutType;
  } else {
    const defaultLayout = getDefaultLayout(state, isMobile);
    layoutType = getLayoutTypeParam({ state, defaultLayout });
  }

  if (isMobile && (isLayoutPGSideBySide(layoutType) || isLayoutPGOneColumn(layoutType))) {
    layoutType = LAYOUT_PG_GRID;
  }

  return layoutType;
};

const getLayoutTypeParam = ({ state, isMobile, defaultLayout }) => {
  return get(getAppSettings(state), getLayoutTypePath(SECTION_POST_LIST, isMobile), defaultLayout);
};
